<template>
<vx-card no-shadow class="card-overflow" :title="car.model" :customTitle="`${car.registrationPlate} (${car.registrationState})`">
    <template slot="actions">
        <vs-button @click="goBack" color="dark">Back</vs-button>
    </template>
    <div class="fill-row-loading">
        <div :class="{'activeLoading':activeLoading}" id="loading-default" class="vs-con-loading__container loading-example">
        </div>
    </div>
    <vs-divider />
    <ValidationObserver ref="form">
        <form-wizard color="rgba(var(--vs-primary), 1)" title="" shape="square" stepSize="sm" backButtonText="Go Back" :subtitle="null" finishButtonText="Submit" @on-complete="bookPass">
            <tab-content title="Select Season Pass" icon="ti-credit-card" class="mb-base" :before-change="handlePass">
                <div class="vx-row w-full flex justify-center mt-base">
                    <div class="vx-col w-full lg:w-1/3 sm:w-1/2 mb-base" v-for="(pass, index) in passData" :key="index" :data="pass">
                        <vx-card class="p-2 border-dark" card-border no-shadow>
                            <div class="text-center">
                                <h4 class="mb-3">{{ pass.name }}</h4>
                                <span>Period: {{ pass.startDate | formatLongDate }} to
                                    {{ pass.endDate | formatLongDate }}</span>
                            </div>
                            <div class="text-center m-base">
                                <h1>{{ pass.priceIncGst | formatCurrency }}</h1>
                                <span>Per Vehicle</span>
                            </div>
                            <vs-button class="w-full mt-6" icon-pack="feather" :icon="
                    selectedPass.id === pass.id
                      ? 'icon-check-circle'
                      : 'icon-circle'
                  " @click="setSelectedPass(pass)" :color="selectedPass.id === pass.id ? 'success' : 'danger'">Buy</vs-button>
                        </vx-card>
                    </div>
                </div>
            </tab-content>
            <tab-content title="Checkout" icon="ti-credit-card" class="mb-base">
                <vs-row class="mb-base">
                    <vs-col vs-justify="flex-start" vs-w="6">
                        <vx-card title="Vehicle Details" class="mt-base">
                            <div class="flex justify-between mb-2">
                                <span>Vehicle</span>
                                <span class="font-semibold"> {{ car.model }}</span>
                            </div>
                            <div class="flex justify-between mb-2">
                                <span>Registration Plate</span>
                                <span class="font-semibold">
                                    {{ car.registrationPlate }} ({{car.registrationState}})
                                </span>
                            </div>
                            <div class="flex justify-between mb-2">
                                <span>Registration Expiry Date</span>
                                <div class="flex">
                                    <span :class="isExpiring ? 'car-exp-strick text-danger' : 'text-success'">
                                        {{ car.registrationExpiryDate | formatLongDate}}
                                    </span>
                                    <vs-button v-if="isExpiring" class="ml-2" size="small" color="danger" @click="updateExpireDate(car)">Validate</vs-button>
                                </div>
                            </div>
                            <div class="flex justify-between mb-2">
                                <span>Season Pass</span>
                                <span class="text-success">{{ selectedPass.name }}</span>
                            </div>
                            <vs-divider />
                            <div class="flex justify-between mb-5 mt-5">
                                <vs-input v-model="promo" class="m-0 mr-5" placeholder="Enter Promocode"></vs-input>
                                <vs-button :disabled="promo === ''" v-show="discountPercentage === 0" @click="validatePromo" color="success">Apply Promo</vs-button>
                                <vs-button v-show="discountPercentage" @click="removePromo" color="dark">Remove Promo</vs-button>
                            </div>
                            <div class="flex justify-between">
                                <span>Total Amount</span>
                                <span class="font-semibold">{{
                    selectedPass.priceIncGst | formatCurrency
                  }}</span>
                            </div>
                            <div v-if="priceAfterDeduction >= 0">
                                <div class="flex justify-between mb-5">
                                    <span>Promo-Code Applied :</span>
                                    <span class="font-semibold">{{
                      -`${discountPercentage}` | formatPercentage
                    }}</span>
                                </div>
                                <div class="flex justify-between">
                                    <span>Amount Payable</span>
                                    <span class="font-semibold">{{
                      priceAfterDeduction | formatCurrency
                    }}</span>
                                </div>
                            </div>
                        </vx-card>
                    </vs-col>
                    <vs-col vs-justify="flex-start" vs-w="6">
                        <VCreditCard v-show="this.priceAfterDeduction > 0" @change="creditInfoChanged" :trans="translations" />
                    </vs-col>
                </vs-row>
            </tab-content>
        </form-wizard>
    </ValidationObserver>
</vx-card>
</template>

<script>
import moment from "moment";
import VCreditCard from "v-credit-card";
import "v-credit-card/dist/VCreditCard.css";
import {
    customMessages
} from "./../../filters/validationmessage";
import {
    FormWizard,
    TabContent
} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VxCard from "../../components/vx-card/VxCard.vue";
import {
    carData
} from "../../store/api/car";

import {
    productData
} from "../../store/api/product";
import {
    treeMixData
} from "../../store/api/treeMix";
import {
    promoData
} from "../../store/api/promo";

const translations = {
    name: {
        label: "Cardholder Name",
        placeholder: "Cardholder Name"
    },
    card: {
        label: "Card Number",
        placeholder: "Card Number"
    },
    expiration: {
        label: "Expiration"
    },
    security: {
        label: "Security Code",
        placeholder: "Security Code"
    }
};

export default {
    props: {},
    watch: {},
    components: {
        FormWizard,
        TabContent,
        VxCard,
        VCreditCard
    },
    data() {
        return {
            car: {},
            ifValidCar: false,
            activeLoading: false,
            customMessages,
            thisIsMyCarConfirm: false,
            priceAfterDeduction: null,
            discountPercentage: 0,
            promo: "",
            carRegistrationPayload: {},
            carId: "",
            passData: [],
            cardData: null,
            selectedPass: "",
            deductionAmount: "",
            translations,
            id: "",
            isExpiring: false
        };
    },
    async created() {
        await this.getCarData();
        await this.loadSeasonPass();
    },

    methods: {
        async getCarData() {
            if (this.$route.params && this.$route.params.id) {
                this.car = await carData.SearchCarRegistrationById(
                    this.$route.params.id
                );
                this.id = this.$route.params.id;

                if (moment(this.car.registrationExpiryDate) <= new Date()) {
                    this.isExpiring = true;
                }
            }
        },
        async updateExpireDate(car) {
            const results = await carData.findCar(
                car.registrationPlate,
                car.registrationState
            );
            if (!results.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: results.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            var carDetail = results.data;
            if (carDetail) {
                var new_date = moment(new Date(), "DD-MM-YYYY");
                if (moment(new_date) <= new Date()) {
                    this.car.registrationExpiryDate = carDetail.expiry_date
                    this.isExpiring = false;
                }
            }
        },
        creditInfoChanged(values) {
            this.cardData = {};
            this.cardData = values;
        },
        changeCar() {
            this.thisIsMyCarConfirm = false;
            this.ifValidCar = false;
        },

        async validatePromo() {
            const validate = await promoData.validatePrompCode(this.promo, this.selectedPass.id);

            if (validate && validate.succeeded === false) {
                this.$vs.notify({
                    title: "Error",
                    text: `${validate.message}`,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }
            this.discountPercentage = validate.discountPercentage;

            this.deductionAmount =
                (this.selectedPass.priceIncGst / 100) * this.discountPercentage;

            this.priceAfterDeduction =
                this.selectedPass.priceIncGst - this.deductionAmount;
        },

        async removePromo() {
            this.discountPercentage = 0;
            this.priceAfterDeduction = this.selectedPass.priceIncGst;
            this.discountPercentage = 0;
            this.promo = "";
        },

        async bookPass() {
            if (this.isExpiring) {
                if (!this.cardData) {
                    this.$vs.notify({
                        title: "Error",
                        text: "Vehicle Registration has expired,<br> Please click on validate to proceed.",
                        color: "danger",
                        position: "top-center",
                        time: 5000,
                    });
                    return;
                }
            }

            if (this.discountPercentage !== 100) {
                if (!this.cardData) {
                    this.$vs.notify({
                        title: "Error",
                        text: "Please enter a valid card details",
                        color: "danger",
                        position: "top-center"
                    });
                    return;
                }

                const dateDiff = moment.utc(
                    moment(this.cardData.expiration, "MM/YY").diff(
                        moment(new Date(), "MM/YY")
                    )
                );

                if (dateDiff < 0) {
                    this.$vs.notify({
                        title: "Error",
                        text: "Card Not Valid",
                        color: "danger",
                        position: "top-center"
                    });
                    return;
                }
            }

            this.carRegistrationPayload.productId = this.selectedPass.id;
            this.carRegistrationPayload = {
                id: this.id,
                ...this.carRegistrationPayload,
                ...this.cardData,
                promoCode: this.promo,
                carRegExpireDate: this.car.registrationExpiryDate,
                discountPercentage: this.discountPercentage
            };

            //Active Loading

            this.activeLoading = true
            this.$vs.loading()

            const request = await carData.RenewCarRegistration(
                this.carRegistrationPayload
            );

            //Disable Loading
            this.activeLoading = false
            this.$vs.loading.close()

            if (!request.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: request.message,
                    color: "danger",
                    position: "top-center",
                    time: 5000,
                });
                return;
            }
            this.$vs.notify({
                title: "Success",
                text: "Record Saved",
                color: "success",
                position: "top-center"
            });
            this.goBack();
        },
        async handleNext() {
            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            if (!this.thisIsMyCarConfirm) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please confirm the car details.",
                    color: "danger",
                    position: "top-center"
                });
                return false;
            }
            return true;
        },
        async loadSeasonPass() {
            this.passData = await productData.SearchProductsOnSale();
        },
        async setSelectedPass(pass) {

            let data = await productData.validateProductPurchase(pass.id);
            if (!data.succeeded) {
                this.$vs.notify({
                    title: "Required",
                    text: data.message,
                    color: "danger",
                    position: "top-center"
                });
                return;
            }

            this.selectedPass = pass;
            this.priceAfterDeduction = this.selectedPass.priceIncGst;
        },
        handlePass() {
            if (this.selectedPass === "") {
                this.$vs.notify({
                    title: "Required",
                    text: "Please select season pass",
                    color: "danger",
                    position: "top-center"
                });
                return false;
            } else {
                return true;
            }
        },
        goBack() {
            this.$router.push("/Vehicles");
        }
    }
};
</script>

<style scoped>
.card-overflow {
    overflow: overlay;
}

.car-exp-strick {
    text-decoration: line-through;
}
</style>
